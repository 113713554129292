import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76ce7461"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrap-error" }
const _hoisted_2 = { class: "error" }
const _hoisted_3 = { class: "error-head" }
const _hoisted_4 = { class: "error-body" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CliveLogoLinkMobile = _resolveComponent("CliveLogoLinkMobile")!
  const _component_AppRouterLink = _resolveComponent("AppRouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, [
        _createVNode(_component_CliveLogoLinkMobile, {
          class: "clive-logo",
          isMobileSize: true
        })
      ]),
      _createElementVNode("main", _hoisted_4, [
        _createElementVNode("strong", {
          class: "title",
          innerHTML: _ctx.title
        }, null, 8, _hoisted_5),
        _createElementVNode("p", {
          class: "description",
          innerHTML: _ctx.description
        }, null, 8, _hoisted_6)
      ]),
      _createVNode(_component_AppRouterLink, {
        class: "btn btn-primary",
        url: '/',
        text: "홈으로 이동"
      })
    ])
  ]))
}